import {apiPut, CONTENT_TYPES} from "./ApiUtils";
import moment from "moment";
import { InitOptions } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';

export function convertDaysToNumber(days: Array<boolean>) {
    let result: number = 0;

    days.forEach((item, index) => {
        if (item) {
            result += Math.pow(2, index);
        }
    });

    return result;
}


export enum SORTING_ENTITIES {
    TOPPING = "/topping/sort",
    TOPPING_GROUP = "/topping/group/sort",
    MENU_ITEM = "/menu-item/sort",
    MENU_ITEM_GROUP = "/menu-item/group/sort",
    DELIVERY_ZONE = "/delivery-zone/sort",
    WHITELABEL_PAYMENT_TYPES = "/whitelabel-admin/restaurant/payment-types/sort",
    PREP_LOCATIONS = "/prep-location/sort"
}

export async function sortEntities(entityPath: SORTING_ENTITIES|string, list: { id: number, sortingNumber: number }[]) {
    await apiPut<void>(entityPath, JSON.stringify(list), CONTENT_TYPES.JSON);
}

export function convertNumberToDays(value: number) {
    if (value) {
        let result: Array<boolean> = [];

        Array.from(value.toString(2).padStart(7, "0")).reverse().forEach((item, index) => {
            result.push(!!parseInt(item));
        })

        return result;
    } else {
        return [false, false, false, false, false, false, false];
    }
}

export function getNavigatorLanguage():string {
    let language = "hu";

    if (typeof window !== "undefined" && typeof window.navigator !== "undefined") {
        language = navigator.language;
    }

    return language;
}

export const convertDateToInputString = (date: Date)=>{
    return moment(date).format("YYYY-MM-DDTHH:mm");
}

export const convertInputStringToDate = (dateString: string)=>{
    return moment(dateString).toDate();
}

export const TINYMCE_INIT: InitOptions & {
    selector?: undefined;
    target?: undefined;
} = {
    height: "calc(100vh - 90px)",
    width: "100%",
    menubar: true,
    paste_data_images: true,
    image_title: true,
    automatic_uploads: true,
    image_advtab: true,
    convert_urls: false,
    resize: true,
    plugins: [
        "advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help imagetools wordcount"
    ],
    toolbar:
        "undo redo paste pastetext | formatselect | fontselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media | removeformat | help",
    file_picker_types: "image",
    content_style: 'img {max-width: 100%; height: auto}',
    file_picker_callback: function (cb:any, value:any, meta:any) {
        let input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.onchange = function () {
            // @ts-ignore
            let file = this.files[0];

            let reader = new FileReader();
            reader.onload = function () {
                let id = "blobid" + (new Date()).getTime();
                // @ts-ignore
                let blobCache = tinymce.activeEditor.editorUpload.blobCache;
                // @ts-ignore
                let base64 = reader.result.split(",")[1];
                let blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);
                cb(blobInfo.blobUri(), {title: file.name});
            };
            reader.readAsDataURL(file);
        };

        input.click();
    }
};
