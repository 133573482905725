import { RestaurantOptionsGroupDTO } from 'libs/common/src/models/RestaurantModels';
import { RESTAURANT_OPTION_TYPE } from './GlobalConstant';
import { apiGet } from './ApiUtils';
import moment from 'moment';

export const GENERAL_RESTAURANT_SERVICE_TYPE: string[] = ['DINE_IN', 'DELIVERY', 'BOTH'];
export const GENERAL_RECEIPTS_GENERAL_TYPE_OF_BLOCK_PRINTING: string[] = [
  'one_printer',
  'one_main_one_kitchen_printer',
  'one_main_more_kitchen_printer'
];

export const getTimeWithUTCOffset = (time: string) => {
  return `${time}+${moment(moment().format('YYYY-MM-DD')).add(moment().utcOffset(), 'minutes').format('HH:mm')}`;
};

export const getTimeFromUTC = (time: string) => {
  // console.log('time PURE',time);
  // console.log('time', moment.utc(`${moment().format('YYYY-MM-DDT')}${time}`).local().format());
  return moment.utc(`${moment().format('YYYY-MM-DDT')}${time}`).local().format('HH:mm:ss');
  // return `${time}+${moment(moment().format('YYYY-MM-DD')).add(moment().utcOffset(), 'minutes').format('HH:mm')}`;
};

export const GENERAL_REPORTING_PERIOD_END: { label: string; value: string }[] = [
  { label: '00:00', value: '00:00:00' },
  { label: '01:00', value: '01:00:00' },
  { label: '02:00', value: '02:00:00' },
  { label: '03:00', value: '03:00:00' },
  { label: '04:00', value: '04:00:00' },
  { label: '05:00', value: '05:00:00' },
  { label: '06:00', value: '06:00:00' },
  { label: '07:00', value: '07:00:00' },
  { label: '08:00', value: '08:00:00' },
  { label: '09:00', value: '09:00:00' },
  { label: '10:00', value: '10:00:00' },
  { label: '11:00', value: '11:00:00' },
  { label: '12:00', value: '12:00:00' },
  { label: '13:00', value: '13:00:00' },
  { label: '14:00', value: '14:00:00' },
  { label: '15:00', value: '15:00:00' },
  { label: '16:00', value: '16:00:00' },
  { label: '17:00', value: '17:00:00' },
  { label: '18:00', value: '18:00:00' },
  { label: '19:00', value: '19:00:00' },
  { label: '20:00', value: '20:00:00' },
  { label: '21:00', value: '21:00:00' },
  { label: '22:00', value: '22:00:00' },
  { label: '23:00', value: '23:00:00' }
];

export const DELIVERY_PHONE_ORDERS_BASE_STATUS: string[] = [
  'SCHEDULED',
  'PENDING',
  'KITCHEN',
  'WAITING_FOR_COURIER',
  'COURIER_ASSIGNED',
  'COURIER_STARTED',
  'COMPLETE',
  'CANCEL',
  'IN_PROGRESS',
  'OVERTIME',
  'PENDING_PAYMENT',
  'RESERVED',
  'WAITING_FOR_PICKUP'
];
export const DINE_IN_TABLES_LAYOUT: string[] = ['disabled', 'layout1d', 'layout2d'];
export const DISCOUNTS_TYPE: string[] = ['no_discount', 'pre_arranged_discount', 'individual_discount'];
export const DELIVERY_COURIER_SETTLEMENT_EXCHANGE_MONEY: string[] = ['yes', 'no', 'required'];
export const DELIVERY_COURIER_SETTLEMENT_EXCHANGE_MONEY_TYPE: string[] = ['individual', 'predefined_list'];
export const DELIVERY_COURIER_SETTLEMENT_TYPE: string[] = ['pay_per_address', 'pay_full_delivery_fee', 'pay_per_zone'];
export const GENERAL_RECEIPTS_MENU_ITEM_FONT_SIZE: string[] = ['1', '2', '3'];
export const GENERAL_RECEIPTS_RESTAURANT_FONT_SIZE: string[] = ['small', 'large'];
export const GENERAL_RECEIPTS_KITCHEN_TOP_MARGIN: string[] = ['cm0', 'cm1', 'cm2', 'cm3', 'cm4', 'cm5', 'cm6'];
export const GENERAL_RECEIPTS_BLOCK_SIZE: string[] = ['mm57', 'mm75'];
export const DELIVERY_ORDERS_FOODPANDA_INDIRECT_STATUS: string[] = [
  'pending',
  'kitchen',
  'courier_assigned',
  'courier_started',
  'complete'
];
export const GENERAL_ORDERS_AUTOMATIC_PRINTING_TYPE_KITCHEN: string[] = [
  'kitchen0db',
  'kitchen1db',
  'kitchen2db',
  'kitchen3db',
  'kitchen4db',
  'kitchen5db'
];
export const GENERAL_ORDERS_AUTOMATIC_PRINTING_TYPE_SUMMARY: string[] = [
  'summary0db',
  'summary1db',
  'summary2db',
  'summary3db',
  'summary4db',
  'summary5db'
];
export const GENERAL_ORDERS_AUTOMATIC_PRINTING_TYPE_COURIER: string[] = [
  'courier0db',
  'courier1db',
  'courier2db',
  'courier3db',
  'courier4db',
  'courier5db'
];
export const DELIVERY_ORDERS_STATUS_AFTER_ACCEPTANCE: string[] = [
  'kitchen',
  'courier_assigned',
  'courier_started',
  'complete'
];
export const DELIVERY_ORDERS_AUTOMATIC_CANCELLATION_TIME: string[] = [
  'no_cancellation',
  'minutes5',
  'minutes10',
  'minutes15',
  'minutes20',
  'minutes25',
  'minutes30',
  'minutes35',
  'minutes40',
  'minutes45',
  'minutes50',
  'minutes55',
  'minutes60',
  'minutes90',
  'oclock2',
  'oclock3',
  'oclock4',
  'oclock5',
  'oclock6'
];
export const GENERAL_POS_QR_TYPE: string[] = ['none', 'cashbox', 'sam4s']; //TODO enable as soon as fixed on POS, "cashcube"];
export const GENERAL_POS_QR_BY: string[] = ['tetelenkenti', 'afabontas'];
export const GENERAL_POS_QR_CONNECTION_TYPE: string[] = ['qr_code'];

export const WHITELABEL_BASIC_GENERAL_BEHAVIOR_SERVICE_TYPE = [
  'DELIVERY',
  'TAKE_AWAY',
  'BOTH'
];
export const WHITELABEL_APPEARANCE_MENU_LAYOUT = ['BOX', 'LIST'];
export const WHITELABEL_APPEARANCE_MENU_PLACEMENT = ['TOP', 'SIDE'];
export const WHITELABEL_INTEGRATIONS_MARKETING_BARION_TYPE = ['BASE', 'FULL'];
export const WHITELABEL_BASIC_GENERAL_PREORDER_DELIVERY_MAX_TIME = [
  'SAME_DAY',
  'NEXT_DAY',
  'IN_3_DAYS',
  'IN_4_DAYS',
  'IN_5_DAYS',
  'IN_6_DAYS',
  'IN_7_DAYS',
  'IN_2_WEEKS',
  'IN_3_WEEKS',
  'IN_4_WEEKS',
  'IN_2_MONTHS'
];
export const WHITELABEL_BASIC_GENERAL_PREORDER_TAKE_AWAY_MAX_TIME = [
  'SAME_DAY',
  'NEXT_DAY',
  'IN_3_DAYS',
  'IN_4_DAYS',
  'IN_5_DAYS',
  'IN_6_DAYS',
  'IN_7_DAYS',
  'IN_2_WEEKS',
  'IN_3_WEEKS',
  'IN_4_WEEKS',
  'IN_2_MONTHS'
];
export const WHITELABEL_BASIC_REVIEWS_NOTIFICATION_TIME = [
  'AFTER_PLACEMENT_30_MINS',
  'AFTER_PLACEMENT_1_HOUR',
  'AFTER_PLACEMENT_2_HOURS',
  'AFTER_PLACEMENT_3_HOURS',
  'AFTER_PLACEMENT_4_HOURS',
  'AFTER_PLACEMENT_5_HOURS',
  'AFTER_PLACEMENT_24_HOURS',
  'AFTER_FULFILLMENT_30_MINS',
  'AFTER_FULFILLMENT_1_HOUR',
  'AFTER_FULFILLMENT_2_HOURS',
  'AFTER_FULFILLMENT_3_HOURS',
  'AFTER_FULFILLMENT_4_HOURS',
  'AFTER_FULFILLMENT_5_HOURS',
  'AFTER_FULFILLMENT_24_HOURS'
];
export const WHITELABEL_BASIC_REVIEWS_FOLLOW_UP_TIME = [
  'AFTER_1_DAY',
  'AFTER_2_DAYS',
  'AFTER_3_DAYS',
  'AFTER_4_DAYS',
  'AFTER_5_DAYS'
];

export const WHITELABEL_APPEARANCE_THEME_PRIMARY_FONT = [
  'raleway',
  'montserrat',
  'caviarDreams',
  'roboto',
  'comfortaa',
  'lato',
  'corbel',
  'poppins',
  'tajawal',
  'rajdhani'
];

export const WHITELABEL_APPEARANCE_THEME_SECONDARY_FONT = [
  'ruthie',
  'baloo',
  'parisienne',
  'nickainley',
  'amaticSc',
  'sacramento',
  'poiretOne',
  'castoro',
  'corinthia',
  'yesevaOne'
];

export function buildInitialValue(data: RestaurantOptionsGroupDTO, initialValue?: any) {
  if (data.subgroups && data.subgroups.length > 0) {
    data.subgroups.map((value) => {
      buildInitialValue(value, initialValue);
    });
  } else {
    if (data.options && data.options.length > 0) {
      data.options.map((value) => {
        let tempValue = value.defaultValue;

        if (value.setting) {
          tempValue = value.setting.value;
        }

        switch (value.type) {
          case RESTAURANT_OPTION_TYPE.INTEGER.textId:
          case RESTAURANT_OPTION_TYPE.DOUBLE.textId:
            initialValue[value.textId] = +tempValue;
            break;
          case RESTAURANT_OPTION_TYPE.SELECT.textId:
            initialValue[value.textId] = {
              label: tempValue,
              value: tempValue
            };
            break;
          case RESTAURANT_OPTION_TYPE.BOOLEAN.textId:
            initialValue[value.textId] = tempValue === 'true';
            break;
          case RESTAURANT_OPTION_TYPE.STRING.textId:
          default:
            initialValue[value.textId] = tempValue;
        }
      });
    }
  }
}

export async function getBooleanSetting(restaurantId: number, settingTextId: string): Promise<boolean> {
  const { data: response } = await apiGet<boolean>(`/restaurant/${restaurantId}/setting/${settingTextId}/boolean`);

  return response;
}

export async function getStringSetting(restaurantId: number, settingTextId: string): Promise<string> {
  const { data: response } = await apiGet<string>(`/restaurant/${restaurantId}/setting/${settingTextId}`);

  return response;
}

export async function getStringSettingWithType<Type>(restaurantId: number, settingTextId: string): Promise<Type> {
  // @ts-ignore
  const { data: response } = await apiGet<Type>(`/restaurant/${restaurantId}/setting/${settingTextId}`);

  return response;
}
