;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"1.0.0"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";
import { PublicRuntimeConfig } from '@orsys/config';

const {sentryApiAdminDsn, environment, version} = PublicRuntimeConfig;

Sentry.init({
  dsn: sentryApiAdminDsn,
  integrations: [Sentry.replayIntegration()],
  tracesSampleRate: environment !== 'prod' ? 0 : 0.1,
  enabled: environment === 'prod',
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: environment,
  release: version,
});
