import { CountryCode } from 'libphonenumber-js';
import { BarionStatus, CURRENCY, OrderSourceMode } from './enum';
import { UserMinimalReadDTO } from './UserModels';

export interface DTO {}

export interface AuditedDTO extends DTO {
  createdBy?: UserMinimalReadDTO;
  lastModifiedBy?: UserMinimalReadDTO;
  createdAt?: Date;
  lastModifiedAt?: Date;
}

export interface SingleTextDTO extends DTO {
  id?: number;
  text: string;
  language?: string;
  availableLanguages?: string[];
}

export interface CoordinateDto {
  lat: number;
  lon: number;
}

export interface AddressDTO extends DTO {
  id?: number;
  additional?: string;
  address?: string;
  companyName?: string;
  country?: string;
  firstName?: string;
  lastName?: string;
  number?: string;
  state?: string;
  vatNumber?: string;
  zip?: string;
  city?: string;
  building?: string;
  floor?: string;
  staircase?: string;
  apartment?: string;
  intercom?: string;
  companyRegistrationNumber?: string;
  position?: CoordinateDto;
  type?: string;
  failedToResolvePosition?: boolean;
  remoteId?: any;
}

export interface AttachmentUploadWrapper extends DTO {
  id?: number;
  dto?: AttachmentCreateDTO;
}

export interface AttachmentCreateDTO extends DTO {
  file: File;
}

export interface AttachmentReadDTO extends DTO {
  fileName: string;
  mimeType: string;
}

export interface SkuIdWrapperDTO extends DTO {
  skuId: string;
}

export interface NutrientsDTO extends DTO {
  energy?: number | string;
  fat?: number | string;
  saturates?: number | string;
  carb?: number | string;
  sugar?: number | string;
  protein?: number | string;
  salt?: number | string;
  monounsaturates?: number | string;
  polyunsaturates?: number | string;
  polyol?: number | string;
  starch?: number | string;
  fiber?: number | string;
  energyKj?: number | string;
}

export interface PasswordChangeDTO extends DTO {
  oldPw?: string;
  newPw: string;
  token?: string;
  email?: string;
}

export interface PhoneDTO extends DTO {
  countryCode?: CountryCode;
  number?: string;
}

export interface AllergenDTO extends DTO {
  name: SingleTextDTO;
  euCode: string;
}

export interface ZoneDTO extends DTO {
  id?: number;
  deliveryAreaId: number;
  name: string;
  color: string;
  minimumOrderValue: string;
  deliveryMinutes: string;
  deliveryFee: string;
  courierFee?: string;
  freeDeliveryThreshold: string;
  enabled: boolean;
  custom: any;
  deliveryDays?: number;
  fromTime?: string | null;
  toTime?: string | null;
  days?: [boolean, boolean, boolean, boolean, boolean, boolean, boolean];
}

export interface BankCard extends DTO {
  id: number;
  type: string;
  lastDigits: string;
  validityYear: number;
  validityMonth: number;
  validityDate: Date;
  token: string;
  primary: boolean;
}

export interface MultiPriceDto {
  singlePriceList: {
    [key: string]: any;
  };
}

export interface PackageReadDto extends DTO {
  id?: number;
  name: SingleTextDTO;
  description: SingleTextDTO;
  price: MultiPriceDto;
  validityInDays: number;
  textId: string;
}

export interface OrsysException extends DTO {
  additionalInfo: string;
  exceptionMessage: string;
}

export interface ApiConnectionDTO extends DTO {
  id?: number;
  restaurant?: number | any;
  source?: string | any;
  mode?: OrderSourceMode | any;
  name?: string;
  venueId?: string;
  apiKey?: string;
  connectionType?: string | any;
  sendMenu?: boolean;
  sendOpenStatus?: boolean;
  sendItemEnabledStatus?: boolean;
}

export interface BarionErrorDto extends DTO {
  authData: string;
  description: string;
  errorCode: string;
  happenedAt: string;
  title: string;
}

export interface BarionTransactionResultDto extends DTO {
  POSTransactionId: string;
  currency: string;
  relatedId: string;
  status: string;
  transactionId: string;
}

export interface BarionPaymentResultDto extends DTO {
  QRUrl: string;
  callbackUrl: string;
  errors: BarionErrorDto[];
  gatewayUrl: string;
  paymentId: string;
  paymentRequestId: string;
  recurrenceResult: string;
  redirectUrl: string;
  status: BarionStatus;
  traceId: null;
  transactions: BarionTransactionResultDto[];
  POSTransactionId: string;
  currency: CURRENCY;
  relatedId: string;
  transactionId: string;
}

export interface TransactionStateDto extends DTO {
  paymentId: string;
  status: BarionStatus;
  cardData: BankCard;
}

export interface OpenStreetMapAddressResult extends DTO {
  place_id: number;
  osm_type: string;
  osm_id: number;
  boundingbox: string[];
  lat: string;
  lon: string;
  display_name: string;
  class: 'place' | 'amenity';
  type: 'house' | 'cafe' | 'pub';
  importance: number;
  address: {
    house_number: string;
    road: string;
    suburb: string;
    city_district: string;
    city: string;
    district: string;
    region: string;
    postcode: string;
    country: string;
    country_code: string;
  };
}

export interface SimpleDateInterval {
  start: Date;
  end: Date;
}

export interface PageAble {
  page: number;
  size: number;
  sort: string[];
}

export type WhitelabelUserAddressDto = {
  id?: number;
  createdAt?: string;
  lastModifiedAt?: string;
  country?: string;
  state?: string;
  city?: string;
  zip?: string;
  address?: string;
  email?: string;
  number?: string;
  building?: string;
  staircase?: string;
  floor?: string;
  apartment?: string;
  intercom?: string;
  additional?: string;
  position?: Position;
  vatNumber?: string;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  companyRegistrationNumber?: string;
  tag?: string;
  type?: AddressType;
  remoteId?: string;
}

export enum AddressType {
  DELIVERY='DELIVERY',
  BILLING='BILLING',
  LOCATION='LOCATION'
}

export type Position = {
  lat: number;
  lon: number;
}
