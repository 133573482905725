import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AddressDTO, CoordinateDto, getAddress } from '@orsys/common';
import axios from 'axios';
import { PublicRuntimeConfig } from '@orsys/config';

const { googleMapsApiKey } = PublicRuntimeConfig;

export function useGoogleMapsCoordinatesByAddressMutation(options?: UseMutationOptions<{
  lat: number,
  lon: number
} | undefined, Error, AddressDTO>) {
  return useMutation({
    mutationFn: async (addressDto: AddressDTO) => {
      let result: CoordinateDto | undefined = undefined;
      const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
        params: {
          address: getAddress(addressDto),
          key: googleMapsApiKey
        }
      });

      // Check if the API returned results
      if (response.data.results.length > 0) {
        const location = response.data.results[0].geometry.location;
        result = { lat: location.lat, lon: location.lng };
      }

      return result;
    },
    ...(options ?? {})
  });
}
