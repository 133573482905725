import { FC, useEffect, useRef, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useOrsysContext } from '@orsys/common';
import { useStopwatch } from 'react-timer-hook';

export const IntercomComponent: FC = () => {
  const { user } = useOrsysContext();
  const { seconds, pause } = useStopwatch({ autoStart: true });
  const { update, hardShutdown, boot, hide, show, getVisitorId, isOpen } = useIntercom();
  const [userLoaded, setUserLoaded] = useState(false);

  useEffect(() => {
    try {
      if (seconds > 0) {
        if (user && !userLoaded) {
          update({
            userId: `${user?.id}`,
            email: user.email,
            name: `${user?.firstName} ${user?.lastName}`
          });
          const chatComponent = document.querySelector<HTMLDivElement>('#intercom-container');
          if (chatComponent) {
            chatComponent.style.display = 'block';
            setUserLoaded(true);
          }
        }
      }
    } catch (e) {
      console.error('e', e);
    }

  }, [user, seconds, userLoaded]);

  useEffect(() => {
    return () => {
      const chatComponent = document.querySelector<HTMLDivElement>('#intercom-container');
      if (chatComponent) {
        chatComponent.style.display = 'none';
      }
    };
  }, []);

  return (
    <>
    </>
  );
};
