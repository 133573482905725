import { SystemRole } from 'libs/common/src/models/enum/SystemRole';
import { RestaurantRole } from 'libs/common/src/models/enum/RestaurantRole';
import { Packages } from 'libs/common/src/models/enum/Subscription';
import { RestaurantServiceType } from 'libs/common/src/models/enum/RestaurantServiceType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBookOpen,
  faCartPlus,
  faChartArea,
  faChartColumn,
  faCircle,
  faCogs, faColonSign, faComputerMouse, faCreditCardAlt,
  faDesktop,
  faDiagramProject,
  faGear, faGears,
  faHandshake, faHandsHelping,
  faHome,
  faKey,
  faListDots,
  faMap,
  faMotorcycle, faPaintBrush,
  faPeopleGroup, faPlaceOfWorship,
  faPlusCircle, faRestroom,
  faScroll,
  faShield,
  faShop, faSitemap,
  faStoreAlt,
  faTable,
  faUser,
  faUsers,
  faUtensils,
  faWarehouse
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';

export interface RouteObject {
	exactMatch?: boolean;
	icon?: JSX.Element | undefined;
	path: string;
	pathTemplate: string;
	textKey: string;
	matchPath?: string;
	matchPaths?: string[];
  externalPath?: string;
	children?: RouteObject[];
	systemRoles?: SystemRole[];
  hideForDemoAccounts?: boolean;
	restaurantRoles?: RestaurantRole[];
	packages?: Packages[];
	restaurantServiceTypes?: RestaurantServiceType[];
}

const mainRoutes: RouteObject = {
	exactMatch: true,
	icon: <FontAwesomeIcon icon={faHome} />,
	path: '',
	pathTemplate: '',
	textKey: 'sidebar.dashboard',
	systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF, SystemRole.SALES_MANAGER, SystemRole.UPLOADER],
};

const systemRoutes: RouteObject = {
	icon: <FontAwesomeIcon icon={faDesktop} />,
	path: 'system',
	pathTemplate: 'system',
	textKey: 'sidebar.system.main',
	systemRoles: [SystemRole.ADMIN],
	children: [
		//     {
		//     icon: <FontAwesomeIcon icon={faCircle} size={"xs"}/>,
		//     path: "cron",
		//     pathTemplate: "cron",
		//     textKey: "sidebar.system.cron",
		//     systemRoles: [
		//         SystemRole.ADMIN
		//     ],
		// },
		{
			icon: (
				<FontAwesomeIcon
					icon={faCircle}
					size={'xs'}
				/>
			),
			path: 'tos',
			pathTemplate: 'tos',
			textKey: 'sidebar.system.tos',
			systemRoles: [SystemRole.ADMIN],
		},
		{
			icon: (
				<FontAwesomeIcon
					icon={faCircle}
					size={'xs'}
				/>
			),
			path: 'blog',
			pathTemplate: 'blog',
			textKey: 'sidebar.system.blog',
			systemRoles: [SystemRole.ADMIN],
			// },{
			//     icon: <FontAwesomeIcon icon={faCircle} size={"xs"}/>,
			//     path: "transaction",
			//     pathTemplate: "transaction",
			//     textKey: "sidebar.system.transaction",
			//     systemRoles: [
			//         SystemRole.ADMIN
			//     ],
		},
		{
			icon: (
				<FontAwesomeIcon
					icon={faCircle}
					size={'xs'}
				/>
			),
			path: 'transaction',
			pathTemplate: 'transaction',
			textKey: 'sidebar.system.transaction',
			systemRoles: [SystemRole.ADMIN],
		},
	],
};

const adminRoutes: RouteObject = {
	icon: <FontAwesomeIcon icon={faShield} />,
	path: 'admin',
	pathTemplate: 'admin',
	textKey: 'sidebar.admin.main',
	systemRoles: [SystemRole.ADMIN],
	children: [
		//     {
		//     icon: <FontAwesomeIcon icon={faCircle} size={"xs"}/>,
		//     path: "profil",
		//     pathTemplate: "profil",
		//     textKey: "sidebar.admin.profil",
		//     systemRoles: [
		//         SystemRole.ADMIN,
		//         SystemRole.SALES_MANAGER,
		//         SystemRole.UPLOADER
		//     ],
		// },
		{
			icon: (
				<FontAwesomeIcon
					icon={faUsers}
					size={'xs'}
				/>
			),
			path: 'staff',
			pathTemplate: 'staff',
			textKey: 'sidebar.admin.staff',
			systemRoles: [SystemRole.ADMIN],
		},
	],
};

const staffRoutes: RouteObject = {
	icon: <FontAwesomeIcon icon={faUsers} />,
	path: 'staff',
	pathTemplate: 'staff',
	textKey: 'sidebar.staff',
	systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF, SystemRole.SALES_MANAGER],
	restaurantRoles: [RestaurantRole.OWNER, RestaurantRole.MANAGER],
};

const restaurantRoutes: RouteObject = {
	icon: <FontAwesomeIcon icon={faStoreAlt} />,
	path: 'restaurant',
	pathTemplate: 'restaurant',
	textKey: 'sidebar.restaurant',
	systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF, SystemRole.SALES_MANAGER, SystemRole.UPLOADER],
	restaurantRoles: [RestaurantRole.OWNER, RestaurantRole.MANAGER, RestaurantRole.UPLOADER],
	children: [
		{
			icon: <FontAwesomeIcon icon={faSitemap} />,
			path: 'prep-location',
			pathTemplate: 'prep-location',
			textKey: 'sidebar.prep_location',
			systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF, SystemRole.SALES_MANAGER, SystemRole.UPLOADER],
			restaurantRoles: [RestaurantRole.OWNER, RestaurantRole.MANAGER, RestaurantRole.UPLOADER],
		},
		{
			icon: <FontAwesomeIcon icon={faBookOpen} />,
			path: 'menu',
			pathTemplate: 'menu',
			textKey: 'sidebar.menu',
			systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF, SystemRole.SALES_MANAGER, SystemRole.UPLOADER],
			restaurantRoles: [RestaurantRole.OWNER, RestaurantRole.MANAGER, RestaurantRole.UPLOADER],
		},
		{
			icon: <FontAwesomeIcon icon={faWarehouse} />,
			path: 'stock',
			pathTemplate: 'stock',
			textKey: 'sidebar.stock',
			systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF],
			restaurantRoles: [RestaurantRole.OWNER],
		},
		{
			icon: <FontAwesomeIcon icon={faTable} />,
			path: 'table',
			pathTemplate: 'table',
			textKey: 'sidebar.table',
			systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF, SystemRole.SALES_MANAGER],
			restaurantRoles: [RestaurantRole.OWNER, RestaurantRole.MANAGER],
			packages: [Packages.mini, Packages.basic, Packages.premium],
			restaurantServiceTypes: [RestaurantServiceType.DINE_IN, RestaurantServiceType.BOTH],
		},
		{
			icon: <FontAwesomeIcon icon={faCircle} />,
			path: 'restaurant',
			pathTemplate: 'restaurant',
			textKey: 'sidebar.restaurant',
			systemRoles: [SystemRole.RESTAURANT_STAFF],
			restaurantRoles: [RestaurantRole.OWNER],
		},
		{
			icon: <FontAwesomeIcon icon={faCircle} />,
			path: 'company',
			pathTemplate: 'company',
			textKey: 'sidebar.company',
			systemRoles: [SystemRole.RESTAURANT_STAFF],
			restaurantRoles: [RestaurantRole.OWNER],
		},
	],
};

const orderRoutes: RouteObject = {
	icon: <FontAwesomeIcon icon={faCartPlus} />,
	path: 'order',
	pathTemplate: 'order',
	textKey: 'sidebar.order.main',
	systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF],
	restaurantRoles: [RestaurantRole.OWNER, RestaurantRole.MANAGER],
	children: [
		{
			icon: (
				<FontAwesomeIcon
					icon={faMotorcycle}
					size={'xs'}
				/>
			),
			path: 'delivery',
			pathTemplate: 'delivery',
			textKey: 'sidebar.order.delivery',
			systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF],
			restaurantRoles: [RestaurantRole.OWNER, RestaurantRole.MANAGER],
			packages: [Packages.basic, Packages.premium],
			restaurantServiceTypes: [RestaurantServiceType.DELIVERY, RestaurantServiceType.BOTH],
		},
		{
			icon: (
				<FontAwesomeIcon
					icon={faUtensils}
					size={'xs'}
				/>
			),
			path: 'local',
			pathTemplate: 'local',
			textKey: 'sidebar.order.local',
			systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF],
			restaurantRoles: [RestaurantRole.OWNER, RestaurantRole.MANAGER],
			packages: [Packages.mini, Packages.basic, Packages.premium],
			restaurantServiceTypes: [RestaurantServiceType.DINE_IN, RestaurantServiceType.BOTH],
		},
	],
};

const partnerRoutes: RouteObject = {
	icon: <FontAwesomeIcon icon={faHandshake} />,
	path: 'partners',
	pathTemplate: 'partners',
	textKey: 'sidebar.partners.main',
	systemRoles: [SystemRole.ADMIN, SystemRole.SALES_MANAGER],
	children: [
		{
			icon: (
				<FontAwesomeIcon
					icon={faShop}
					size={'xs'}
				/>
			),
			path: 'restaurants',
			pathTemplate: 'restaurants',
			textKey: 'sidebar.partners.restaurants',
			systemRoles: [SystemRole.ADMIN, SystemRole.SALES_MANAGER],
		},
		{
			icon: (
				<FontAwesomeIcon
					icon={faPeopleGroup}
					size={'xs'}
				/>
			),
			path: 'owners',
			pathTemplate: 'owners',
			textKey: 'sidebar.partners.owners',
			systemRoles: [SystemRole.ADMIN],
		},
		{
			icon: (
				<FontAwesomeIcon
					icon={faPlusCircle}
					size={'xs'}
				/>
			),
			path: 'demo',
			pathTemplate: 'demo',
			textKey: 'sidebar.partners.demo',
			systemRoles: [SystemRole.ADMIN],
		},
	],
};

const correspondenceRoutes: RouteObject = {
	icon: <FontAwesomeIcon icon={faCircle} />,
	path: 'correspondence',
	pathTemplate: 'correspondence',
	textKey: 'sidebar.correspondence.main',
	systemRoles: [SystemRole.ADMIN],
	children: [
		{
			icon: (
				<FontAwesomeIcon
					icon={faCircle}
					size={'xs'}
				/>
			),
			path: 'sendemail',
			pathTemplate: 'sendemail',
			textKey: 'sidebar.correspondence.send_email',
			systemRoles: [SystemRole.ADMIN],
		},
		{
			icon: (
				<FontAwesomeIcon
					icon={faCircle}
					size={'xs'}
				/>
			),
			path: 'sentletters',
			pathTemplate: 'sentletters',
			textKey: 'sidebar.correspondence.sent_letters',
			systemRoles: [SystemRole.ADMIN],
		},
	],
};

const apiRoutes: RouteObject = {
	icon: <FontAwesomeIcon icon={faDiagramProject} />,
	path: 'apis',
	pathTemplate: 'apis',
	textKey: 'sidebar.api.main',
	systemRoles: [SystemRole.ADMIN],
	children: [
		{
			icon: (
				<FontAwesomeIcon
					icon={faCircle}
					size={'xs'}
				/>
			),
			path: 'connections',
			pathTemplate: 'connections',
			textKey: 'sidebar.api.api_connections',
			systemRoles: [SystemRole.ADMIN],
			// },{
			//     icon: <FontAwesomeIcon icon={faCircle} size={"xs"}/>,
			//     path: "log",
			//     pathTemplate: "log",
			//     textKey: "sidebar.api.api_log",
			//     systemRoles: [
			//         SystemRole.ADMIN
			//     ],
			// },{
			//     icon: <FontAwesomeIcon icon={faCircle} size={"xs"}/>,
			//     path: "blockprints",
			//     pathTemplate: "blockprints",
			//     textKey: "sidebar.api.block_prints",
			//     systemRoles: [
			//         SystemRole.ADMIN
			//     ],
		},
	],
};

const statisticRoutes: RouteObject = {
	icon: <FontAwesomeIcon icon={faChartColumn} />,
	path: 'statistics',
	pathTemplate: 'statistics',
	textKey: 'sidebar.statistics.main',
	systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF],
	restaurantRoles: [RestaurantRole.OWNER, RestaurantRole.MANAGER],
	children: [
		{
			icon: (
				<FontAwesomeIcon
					icon={faChartArea}
					size={'xs'}
				/>
			),
			matchPaths: ['/statistics/total-traffic', '/statistics/on-site-sales', '/statistics/delivery-sales'],
			path: '',
			pathTemplate: '',
			textKey: 'sidebar.statistics.traffic',
			systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF],
			restaurantRoles: [RestaurantRole.OWNER, RestaurantRole.MANAGER],
		},
		{
			icon: (
				<FontAwesomeIcon
					icon={faBookOpen}
					size={'xs'}
				/>
			),
			exactMatch: true,
			path: 'menu',
			pathTemplate: 'menu',
			textKey: 'sidebar.menu',
			systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF],
			restaurantRoles: [RestaurantRole.OWNER, RestaurantRole.MANAGER],
		},
		{
			icon: (
				<FontAwesomeIcon
					icon={faBookOpen}
					size={'xs'}
				/>
			),
			matchPaths: [
				'/statistics/total-traffic/day-of-week/hourly',
				'/statistics/on-site-sales/day-of-week/hourly',
				'/statistics/delivery-sales/day-of-week/hourly',
			],
			path: 'total-traffic/day-of-week/hourly',
			pathTemplate: '[trafficType]/day-of-week/[dayOfWeekType]',
			textKey: 'sidebar.statistics.order_distribution',
			systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF],
			restaurantRoles: [RestaurantRole.OWNER, RestaurantRole.MANAGER],
		},
		{
			icon: (
				<FontAwesomeIcon
					icon={faBookOpen}
					size={'xs'}
				/>
			),
			matchPaths: [
				'/statistics/total-traffic/day-of-week/daily',
				'/statistics/on-site-sales/day-of-week/daily',
				'/statistics/delivery-sales/day-of-week/daily',
			],
			path: 'total-traffic/day-of-week/daily',
			pathTemplate: '[trafficType]/day-of-week/[dayOfWeekType]',
			textKey: 'sidebar.statistics.days_of_week',
			systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF],
			restaurantRoles: [RestaurantRole.OWNER, RestaurantRole.MANAGER],
		},
		{
			icon: (
				<FontAwesomeIcon
					icon={faBookOpen}
					size={'xs'}
				/>
			),
			matchPaths: [
				'/statistics/total-traffic/payments',
				'/statistics/on-site-sales/payments',
				'/statistics/delivery-sales/payments',
			],
			path: 'total-traffic/payments',
			pathTemplate: '[trafficType]/payments',
			textKey: 'sidebar.statistics.payments',
			systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF],
			restaurantRoles: [RestaurantRole.OWNER, RestaurantRole.MANAGER],
		},
		// {
		//     icon: <FontAwesomeIcon icon={faUtensils} size={"xs"}/>,
		//     path: "on-site-sales",
		//     pathTemplate: "on-site-sales",
		//     textKey: "sidebar.statistics.on_site_sales",
		//     systemRoles: [
		//         SystemRole.ADMIN,
		//         SystemRole.RESTAURANT_STAFF,
		//     ],
		//     restaurantRoles: [
		//         RestaurantRole.OWNER,
		//         RestaurantRole.MANAGER
		//     ],
		// },
		// {
		//     icon: <FontAwesomeIcon icon={faMotorcycle} size={"xs"}/>,
		//     path: "delivery-sales",
		//     pathTemplate: "delivery-sales",
		//     textKey: "sidebar.statistics.delivery_sales",
		//     systemRoles: [
		//         SystemRole.ADMIN,
		//         SystemRole.RESTAURANT_STAFF,
		//     ],
		//     restaurantRoles: [
		//         RestaurantRole.OWNER,
		//         RestaurantRole.MANAGER
		//     ],
		// }
	],
};

const settlementRoutes: RouteObject = {
	icon: <FontAwesomeIcon icon={faScroll} />,
	path: 'settlement',
	pathTemplate: 'settlement',
	textKey: 'sidebar.settlement.main',
	systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF],
	restaurantRoles: [RestaurantRole.OWNER],
	children: [
		{
			icon: (
				<FontAwesomeIcon
					icon={faCircle}
					size={'xs'}
				/>
			),
			path: 'restaurant',
			pathTemplate: 'restaurant',
			textKey: 'sidebar.settlement.restaurant',
			systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF],
			restaurantRoles: [RestaurantRole.OWNER, RestaurantRole.MANAGER],
		},
		{
			icon: (
				<FontAwesomeIcon
					icon={faCircle}
					size={'xs'}
				/>
			),
			path: 'courier',
			pathTemplate: 'courier',
			textKey: 'sidebar.settlement.courier',
			systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF],
			restaurantRoles: [RestaurantRole.OWNER, RestaurantRole.MANAGER],
			packages: [Packages.basic, Packages.premium],
			restaurantServiceTypes: [RestaurantServiceType.DELIVERY, RestaurantServiceType.BOTH],
		},
	],
};

const deliveryRoutes: RouteObject = {
	icon: <FontAwesomeIcon icon={faMotorcycle} />,
	path: 'delivery',
	pathTemplate: 'delivery',
	textKey: 'sidebar.delivery.main',
	systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF, SystemRole.SALES_MANAGER, SystemRole.UPLOADER],
	restaurantRoles: [RestaurantRole.OWNER, RestaurantRole.MANAGER, RestaurantRole.UPLOADER],
	packages: [Packages.basic, Packages.premium],
	restaurantServiceTypes: [RestaurantServiceType.DELIVERY, RestaurantServiceType.BOTH],
	children: [
		{
			icon: <FontAwesomeIcon icon={faMap} />,
			path: 'delivery-area',
			pathTemplate: 'delivery-area',
			textKey: 'sidebar.delivery.delivery_area',
			systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF, SystemRole.SALES_MANAGER, SystemRole.UPLOADER],
			restaurantRoles: [RestaurantRole.OWNER, RestaurantRole.MANAGER, RestaurantRole.UPLOADER],
			packages: [Packages.basic, Packages.premium],
			restaurantServiceTypes: [RestaurantServiceType.DELIVERY, RestaurantServiceType.BOTH],
		},
		{
			icon: (
				<FontAwesomeIcon
					icon={faCircle}
					size={'xs'}
				/>
			),
			path: 'customers',
			pathTemplate: 'customers',
			textKey: 'sidebar.delivery.users',
			systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF],
			restaurantRoles: [RestaurantRole.OWNER, RestaurantRole.MANAGER],
			packages: [Packages.basic, Packages.premium],
			restaurantServiceTypes: [RestaurantServiceType.DELIVERY, RestaurantServiceType.BOTH],
		},
	],
};

const ntakRoutes: RouteObject = {
	icon: (
		<img
			width={18}
			src={'/icons/ntak-logo-feher.svg'}
		/>
	),
	path: 'ntak',
	pathTemplate: 'ntak',
	textKey: 'sidebar.ntak',
	systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF],
	restaurantRoles: [RestaurantRole.OWNER, RestaurantRole.MANAGER],
	children: [
		{
			icon: <FontAwesomeIcon icon={faGear} />,
			path: 'settings',
			pathTemplate: 'settings',
			textKey: 'button.setting',
			systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF],
			restaurantRoles: [RestaurantRole.OWNER, RestaurantRole.MANAGER],
		},
		{
			icon: <FontAwesomeIcon icon={faChartArea} />,
			path: 'history',
			pathTemplate: 'history',
			textKey: 'general.history',
			systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF],
			restaurantRoles: [RestaurantRole.OWNER, RestaurantRole.MANAGER],
		},
		{
			icon: (
				<FontAwesomeIcon
					icon={faCircle}
					size={'xs'}
				/>
			),
			path: 'daily-report',
			pathTemplate: 'daily-report',
			textKey: 'general.daily-report',
			systemRoles: [SystemRole.ADMIN],
		},
	],
};

const settingsRoutes: RouteObject = {
	icon: <FontAwesomeIcon icon={faCogs} />,
	path: 'settings',
	pathTemplate: 'settings',
	textKey: 'sidebar.settings.main',
	children: [
		{
			icon: <FontAwesomeIcon icon={faUser} />,
			path: 'profile',
			pathTemplate: 'profile',
			textKey: 'sidebar.settings.profile',
		},
		{
			icon: (
				<FontAwesomeIcon
					icon={faCircle}
					size={'xs'}
				/>
			),
			path: 'subscription',
			pathTemplate: 'subscription',
			textKey: 'sidebar.settings.subscription',
			systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF, SystemRole.SALES_MANAGER],
			restaurantRoles: [RestaurantRole.OWNER, RestaurantRole.MANAGER],
		},
		{
			icon: <FontAwesomeIcon icon={faKey} />,
			path: 'change-password',
			pathTemplate: 'change-password',
			textKey: 'sidebar.settings.change_password',
		},
		{
			icon: (
				<FontAwesomeIcon
					icon={faCircle}
					size={'xs'}
				/>
			),
			path: 'general/general_restaurant_tab',
			pathTemplate: '[setting]/[tab]',
			matchPath: 'general/',
			textKey: 'sidebar.settings.general',
			systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF, SystemRole.SALES_MANAGER],
			restaurantRoles: [RestaurantRole.OWNER, RestaurantRole.MANAGER],
		},
		{
			icon: (
				<FontAwesomeIcon
					icon={faCircle}
					size={'xs'}
				/>
			),
			path: 'delivery/delivery_orders_tab',
			pathTemplate: '[setting]/[tab]',
			matchPath: 'delivery/',
			textKey: 'sidebar.settings.delivery',
			systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF, SystemRole.SALES_MANAGER],
			restaurantRoles: [RestaurantRole.OWNER, RestaurantRole.MANAGER],
			packages: [Packages.basic, Packages.premium],
			restaurantServiceTypes: [RestaurantServiceType.DELIVERY, RestaurantServiceType.BOTH],
		},
		{
			icon: (
				<FontAwesomeIcon
					icon={faCircle}
					size={'xs'}
				/>
			),
			path: 'local/dine_in_general_tab',
			pathTemplate: '[setting]/[tab]',
			matchPath: 'local/',
			textKey: 'sidebar.settings.local',
			systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF, SystemRole.SALES_MANAGER],
			restaurantRoles: [RestaurantRole.OWNER, RestaurantRole.MANAGER],
			packages: [Packages.mini, Packages.basic, Packages.premium],
			restaurantServiceTypes: [RestaurantServiceType.DINE_IN, RestaurantServiceType.BOTH],
		},
	],
};

const wishlistRoutes: RouteObject = {
	exactMatch: true,
	icon: <FontAwesomeIcon icon={faListDots} />,
  hideForDemoAccounts: true,
	path: 'wishlist',
	pathTemplate: 'wishlist',
	textKey: 'wishlist.wishlist',
	systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF, SystemRole.SALES_MANAGER, SystemRole.UPLOADER],
};

const helpRoutes: RouteObject = {
  exactMatch: true,
  icon: <FontAwesomeIcon icon={faHandsHelping} />,
  path: 'helpCenter',
  pathTemplate: 'helpCenter',
  externalPath: 'https://help.orsys.hu',
  textKey: 'helpCenter',
  systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF, SystemRole.SALES_MANAGER, SystemRole.UPLOADER]
};


const whitelabelRoutes: RouteObject = {
  icon: (
    <FontAwesomeIcon icon={faComputerMouse}/>
  ),
  path: 'whitelabel',
  pathTemplate: 'whitelabel',
  textKey: 'sidebar.whitelabel',
  systemRoles: [SystemRole.ADMIN, ],//SystemRole.RESTAURANT_STAFF, SystemRole.SALES_MANAGER, SystemRole.UPLOADER],
  restaurantRoles: [RestaurantRole.OWNER, RestaurantRole.MANAGER, RestaurantRole.UPLOADER],
  children: [
    {
      icon: <FontAwesomeIcon icon={faGears} />,
      path: 'settings/whitelabel_basic/whitelabel_basic_general',
      pathTemplate: 'settings/[setting]/[tab]',
      textKey: 'whitelabel.settings.basic',
      systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF],
      restaurantRoles: [RestaurantRole.OWNER, RestaurantRole.MANAGER],
    },
    {
      icon: <FontAwesomeIcon icon={faPaintBrush} />,
      path: 'settings/whitelabel_appearance/whitelabel_appearance_theme_tab',
      pathTemplate: 'settings/[setting]/[tab]',
      textKey: 'whitelabel.settings.appearance',
      systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF],
      restaurantRoles: [RestaurantRole.OWNER, RestaurantRole.MANAGER],
    },
    {
      icon: <FontAwesomeIcon icon={faPaintBrush} />,
      path: 'settings/whitelabel_integrations/whitelabel_integrations_marketing',
      pathTemplate: 'settings/[setting]/[tab]',
      textKey: 'whitelabel.settings.integrations',
      systemRoles: [SystemRole.ADMIN, SystemRole.RESTAURANT_STAFF],
      restaurantRoles: [RestaurantRole.OWNER, RestaurantRole.MANAGER],
    },
  ],
};

export const routes: RouteObject[] = [
	mainRoutes,
	systemRoutes,
	adminRoutes,
	staffRoutes,
	restaurantRoutes,
	orderRoutes,
	partnerRoutes,
	// correspondenceRoutes,
	apiRoutes,
	statisticRoutes,
	settlementRoutes,
	deliveryRoutes,
	ntakRoutes,
	settingsRoutes,
	wishlistRoutes,
  helpRoutes,
  whitelabelRoutes
];
