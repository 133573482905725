import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { WithIntlComponentProps } from 'libs/common/src/utils/i18n';
import Modal from '../Modal/Modal';
import styles from './InfoIconComponent.module.scss';

interface InfoIconComponentProps extends WithIntlComponentProps {
	content: string;
	title: string;
}

interface InfoIconComponentState {
	modalOpen: boolean;
}

class InfoIconComponent extends PureComponent<InfoIconComponentProps, InfoIconComponentState> {
	readonly state: InfoIconComponentState = {
		modalOpen: false,
	};

	toggleModal = () => {
		this.setState(({ modalOpen }) => ({ modalOpen: !modalOpen }));
	};

	getModal = () => {
		const { intl, content, title } = this.props;
		const { modalOpen } = this.state;

		return (
			<Modal
				title={intl.formatMessage({ id: title })}
				onClose={this.toggleModal}
				closeButton
				open={modalOpen}
				borderBottom>
				{intl.formatMessage({ id: content })}
			</Modal>
		);
	};

	render() {
		const { intl } = this.props;

		return (
			<>
				<button
					type={'button'}
					onClick={this.toggleModal}
					className={styles.infoButton}>
					<FontAwesomeIcon icon={faInfo} />
				</button>
				{this.getModal()}
			</>
		);
	}
}

export default injectIntl(InfoIconComponent);
