import { faCheck, faEye, faEyeSlash, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { default as classNames, default as cn } from 'classnames';
import { ErrorMessage, Field } from 'formik';
import { FieldProps } from 'formik/dist/Field';
import { CountryCode } from 'libphonenumber-js';
import React, { FormEventHandler, ReactNode, useState } from 'react';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/input';
import Select from 'react-select';
import { OptionsOrGroups } from 'react-select/dist/declarations/src/types';
import TextareaAutosize from 'react-textarea-autosize';
import styles from './Input.module.scss';
import InfoIconComponent from '../SettingComponent/InfoIconComponent';

export enum InputSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

type Onchange = (value?: any | undefined) => void;

// @ts-ignore
export interface InputProps extends React.HTMLProps<HTMLInputElement> {
  inputLabel?: string | ReactNode;
  inputLabelSuffix?: string;
  inputLabelInfo?: {
    title?: string;
    content?: string;
  };
  error?: string;
  touched?: boolean;
  validate?: boolean;
  vertical?: boolean;
  value?: any;
  simpleHtmlInput?: boolean;
  prefixComponent?: React.ReactNode | string;
  suffixComponent?: React.ReactNode | string;
  options?: OptionsOrGroups<any, any>;
  isMulti?: boolean;
  isSearchable?: boolean;
  onChange?: FormEventHandler<HTMLInputElement> | FormEventHandler<HTMLTextAreaElement> | Onchange | undefined;
  isClearable?: boolean;
  isRequire?: boolean;
  textarea?: boolean;
  minRows?: number;
  country?: CountryCode;
  inputSize?: InputSize;
  withBorder?: boolean;
}

const Input = (props: InputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const {
    vertical,
    className,
    id,
    name,
    inputLabel,
    inputLabelSuffix,
    inputLabelInfo,
    placeholder,
    type,
    error,
    touched,
    validate,
    disabled,
    value,
    simpleHtmlInput,
    prefixComponent,
    suffixComponent,
    children,
    options,
    isMulti,
    as,
    isClearable,
    isRequire,
    maxLength,
    textarea,
    inputSize,
    onChange,
    onBlur,
    onFocus,
    min,
    max,
    step,
    required,
    autoComplete,
    country,
    isSearchable,
    minRows,
    withBorder = true
  } = props;

  const inputLabelInfoComponent = inputLabelInfo ? (
    <>
      <InfoIconComponent {...inputLabelInfo} />
    </>
  ) : null;

  if (type === 'checkbox') {
    return (
      <label
        className={`${inputSize ? styles[inputSize] : ''} ${className ? className : ''} ${
          disabled ? styles.disabled : ''
        } ${type ? styles[type] : ''}`}>
        {simpleHtmlInput ? (
          <input {...(props as React.HTMLProps<HTMLInputElement>)} />
        ) : (
          <Field
            id={id}
            autoComplete={autoComplete}
            type={type}
            name={name}
            disabled={disabled}
          />
        )}
        <div className={cn(styles.label, { [styles.hasInfo]: inputLabelInfoComponent })}>
          {inputLabel}
          {inputLabelInfoComponent}
        </div>
      </label>
    );
  }
  if (type === 'radio') {
    return (
      <label
        className={`${inputSize ? styles[inputSize] : ''} ${className ? className : ''} ${
          disabled ? styles.disabled : ''
        } ${type ? styles[type] : ''}`}>
        {simpleHtmlInput ? (
          <input {...(props as React.HTMLProps<HTMLInputElement>)} />
        ) : (
          <Field
            id={id}
            type={type}
            name={name}
            autoComplete={autoComplete}
            value={value}
            disabled={disabled}
          />
        )}
        <div className={cn(styles.label, { [styles.hasInfo]: inputLabelInfoComponent })}>
          {inputLabel}
          {inputLabelInfoComponent}
        </div>
      </label>
    );
  }

  let content = null;
  if (options) {
    content = (
      <>
        {simpleHtmlInput ? (
          <Select
            options={options}
            id={id}
            isMulti={isMulti}
            name={name}
            className={cn('react-select-container', styles.select)}
            classNamePrefix={'react-select'}
            isDisabled={disabled}
            placeholder={placeholder}
            onChange={onChange}
            isClearable={isClearable}
            isSearchable={isSearchable}
            value={value}
          />
        ) : (
          <Field
            id={id}
            name={name}
            type={type}
            placeholder={placeholder}
            disabled={disabled}>
            {({
                field, // { name, value, onChange, onBlur }
                form: { setFieldValue, setFieldTouched } // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
              }: FieldProps) => (
              <Select
                options={options}
                id={id}
                isMulti={isMulti}
                name={name}
                className={cn('react-select-container', styles.select)}
                classNamePrefix={'react-select'}
                isDisabled={disabled}
                placeholder={placeholder}
                onFocus={() => {
                  setFieldTouched(name ? name : '', true);
                }}
                onBlur={field.onBlur}
                onChange={(values) => {
                  if (onChange) {
                    onChange(values);
                  } else {
                    setFieldValue(name ? name : '', values);
                  }
                }}
                isClearable={isClearable}
                value={value}
              />
            )}
          </Field>
        )}
      </>
    );
  } else if (type === 'tel') {
    let phoneOptions: OptionsOrGroups<any, any> = getCountries().map((countryCode) => ({
      label: `${countryCode} (+${getCountryCallingCode(countryCode)})`,
      value: countryCode
    }));
    content = (
      <>
        {simpleHtmlInput ? (
          <PhoneInput
            country={country}
            international={true}
            value={value ? value : ''}
            id={id}
            onChange={onChange as Onchange}
            onFocus={onFocus}
            onBlur={onBlur}
            dir={'ltr'}
            name={id}
            placeholder={placeholder}
            disabled={disabled}
            required={required}
            className={styles.textField}
            autoComplete={autoComplete}
            type={type}
          />
        ) : (
          <Field
            id={id}
            name={name}
            type={type}
            placeholder={placeholder}
            disabled={disabled}>
            {({
                field, // { name, value, onChange, onBlur }
                form: { setFieldValue } // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
              }: FieldProps) => (
              <PhoneInput
                country={country}
                international={true}
                value={value ? value : ''}
                id={id}
                placeholder={placeholder}
                onChange={(value) => {
                  setFieldValue(name as string, value);
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                dir={'ltr'}
                name={id}
                disabled={disabled}
                required={required}
                autoComplete={autoComplete}
                type={type}
              />
            )}
          </Field>
        )}
      </>
    );
  } else if (textarea) {
    content = (
      <>
        {simpleHtmlInput ? (
          <TextareaAutosize
            id={id}
            name={name}
            disabled={disabled}
            placeholder={placeholder}
            maxLength={maxLength}
            minRows={minRows || 3}
            onChange={onChange as FormEventHandler<HTMLTextAreaElement>}
            value={value}
          />
        ) : (
          <Field
            id={id}
            name={name}
            type={type}
            placeholder={placeholder}
            disabled={disabled}>
            {({
                field, // { name, value, onChange, onBlur }
                form: { setFieldValue } // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
              }: FieldProps) => (
              <TextareaAutosize
                id={id}
                name={name}
                autoComplete={autoComplete}
                disabled={disabled}
                placeholder={placeholder}
                onBlur={field.onBlur}
                maxLength={maxLength}
                minRows={minRows || 3}
                onChange={(event) => {
                  setFieldValue(name ? name : '', event.currentTarget.value);
                }}
                value={field.value}
                defaultValue={field.value}
              />
            )}
          </Field>
        )}
      </>
    );
  } else {
    content = (
      <>
        {simpleHtmlInput ? (
          <input {...(props as React.HTMLProps<HTMLInputElement>)} />
        ) : (
          <Field
            id={id}
            as={as}
            name={name}
            maxLength={maxLength}
            min={min}
            max={max}
            step={step}
            autoComplete={autoComplete}
            type={showPassword ? 'text' : type}
            placeholder={placeholder}
            disabled={disabled}>
            {children}
          </Field>
        )}
      </>
    );
  }

  return (
    <div
      className={cn(styles.input, {
        [styles[inputSize || '']]: inputSize,
        [styles.select]: options,
        [styles.vertical]: vertical,
        [styles.horizontal]: !vertical,
        [styles.success]: validate && !error && touched,
        [styles.error]: validate && error && touched,
        [styles.disabled]: disabled,
        [className || '']: className,
        [styles.type]: type,
        [styles.noLabel]: !inputLabel
      })}>
      {inputLabel && (
        <label className={cn(styles.label, { [styles.hasInfo]: inputLabelInfoComponent, [styles.require]: isRequire })}>
          <span>
					  {inputLabel}
            {required ? ' *' : ''}{' '}
            {inputLabelSuffix ? <span style={{ fontSize: 'small' }}> - {inputLabelSuffix}</span> : ''}
          </span>
          {inputLabelInfoComponent}
        </label>
      )}
      <div className={styles.outerContainer}>
        <div
          className={classNames({
            [styles.inputContainer]: true,
            [styles.textarea]: textarea,
            [styles.withoutBorder]: !withBorder
          })}>
          {prefixComponent && (
            <div className={`${styles.prefix} ${styles[type as string]}`}>{prefixComponent}</div>
          )}
          {content}
          {/*<FontAwesomeIcon className={`${styles.icon} ${styles.selectArrow}`} icon={faChevronUp}/>*/}
          {type === 'password' && !simpleHtmlInput && (
            <div
              onClick={() => setShowPassword(!showPassword)}
              className={`${styles.passwordToggle} ${showPassword ? styles.active : ''}`}>
              <FontAwesomeIcon
                className={`${styles.icon} ${styles.show}`}
                icon={faEye}
              />
              <FontAwesomeIcon
                className={`${styles.icon} ${styles.hide}`}
                icon={faEyeSlash}
              />
            </div>
          )}
          {validate && touched && error && !options && (
            <FontAwesomeIcon
              className={styles.icon}
              icon={faTimes}
            />
          )}
          {touched && validate && !error && !options && (
            <FontAwesomeIcon
              className={styles.icon}
              icon={faCheck}
            />
          )}
          {suffixComponent && <div className={styles.suffix}>{suffixComponent}</div>}
        </div>
        {!simpleHtmlInput && (
          <>
            <ErrorMessage
              className={styles.message}
              name={name as string}
              component="div"
            />
            {(!error || (error && !touched)) && <div className={styles.messagePlaceholder} />}
          </>
        )}
      </div>
    </div>
  );
};

export default Input;
